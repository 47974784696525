<template>
  <div>
    <div
      v-if="model.influencer_preview"
      class="d-flex align-center cursor-pointer"
    >
      <profile-chip
        :data="model.influencer_preview"
        :platform="model.platform"
      />
    </div>
    <div
      v-else
      :title="model.query"
    >
      {{ model.query.length > 40 ? model.query.substring(0, 37) + '...' : model.query }}
    </div>
  </div>
</template>

<script>
// Use the profile chip component
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip.vue')

// Export the SFC
export default {
  // Name of the component
  name: "QueryChip",

  // Register children components
  components: {
    ProfileChip
  },

  // Accept incoming data from parent
  props: {
    // The entire backend model
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
